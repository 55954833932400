import {
	createApp
} from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import App from './App.vue'
import qs from 'qs'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'
const app = createApp(App).use(router).use(store).use(qs).use(VueQuillEditor, /* { default global options } */ )
installElementPlus(app),

	app.mount('#app')
